<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <p>Drop us Message for any Query</p>
        </div>
    </div>
</div> -->

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>بعض الأسباب الأخرى لاختيارنا</h3>
                        <p>اتخذ الخطوات الأولى نحو زيادة الإنتاجية وتقليل التوتر مع Laststep.</p>
                        <p>نحن فريق مدرب بشكل جيد ومخصص، نساعد رواد الأعمال والمحترفين والفرق الصغيرة على تحقيق النجاح -
                            ليس فقط في العمل، ولكن في الحياة أيضًا. نحن نساعدك على توفير وقتك للتركيز على الصورة الكبيرة
                            من خلال العناية بالتفاصيل الصغيرة.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> فريق عمل متخصص ومتواجد في مصر</li>
                            <li><i class='bx bx-badge-check'></i> إمكانية التوسع أو التقليص حسب الطلب</li>
                            <li><i class='bx bx-badge-check'></i> مشاريع لمرة واحدة أو مشاريع مستمرة</li>
                            <li><i class='bx bx-badge-check'></i> التوظيف بناءً على المهارات والخبرة</li>
                            <li><i class='bx bx-badge-check'></i> خدمات شحن موثوقة وسريعة</li>
                            <li><i class='bx bx-badge-check'></i> تتبع فوري للشحنات لضمان وصولها بسلامة</li>
                            <li><i class='bx bx-badge-check'></i> دعم عملاء متاح على مدار الساعة</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>تواصل معانا</h3>
                        <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" [(ngModel)]="contact.name"
                                            class="form-control" required placeholder="الاسم" #name="ngModel">
                                        <div *ngIf="name.invalid && (name.dirty || name.touched)"
                                            class="alert alert-danger">
                                            الاسم مطلوب
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" [(ngModel)]="contact.email"
                                            class="form-control" required placeholder="الايميل" #email="ngModel">
                                        <div *ngIf="email.invalid && (email.dirty || email.touched)"
                                            class="alert alert-danger">
                                            الايميل مطلوب
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone" id="phone" [(ngModel)]="contact.phone" required
                                            class="form-control" placeholder="رقم الهاتف" #phone="ngModel">
                                        <div *ngIf="phone.invalid && (phone.dirty || phone.touched)"
                                            class="alert alert-danger">
                                            رقم الهاتف مطلوب
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="address" id="address" [(ngModel)]="contact.address"
                                            class="form-control" required placeholder="العنوان" #address="ngModel">
                                        <div *ngIf="address.invalid && (address.dirty || address.touched)"
                                            class="alert alert-danger">
                                            العنوان مطلوب
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message"
                                            [(ngModel)]="contact.message" cols="30" rows="6" required
                                            placeholder="رسالتك" #message="ngModel"></textarea>
                                        <div *ngIf="message.invalid && (message.dirty || message.touched)"
                                            class="alert alert-danger">
                                            الرسالة مطلوبة
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn shadow-none"
                                        [disabled]="contactForm.invalid" style="box-shadow: none;">
                                        <i class='bx bxs-paper-plane'></i> ارسال <span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="contact-info">
                <div class="contact-info-content">
                    <!-- <h3>Contact us by Phone Number or Email Address</h3>
                    <h2>
                        <a href="tel:+0881306298615">+088 130 629 8615</a>
                        <span>OR</span>
                        <a href="mailto:hello&#64;jexsa.com">hello&#64;jexsa.com</a>
                    </h2> -->
                    <ul class="social">
                        <!-- <li><a href="#" target="_blank">
                            <i class="bx bxl-twitter"></i>
                            <a href="https://twitter.com/aramex" class="amx-twtr" rel="nofollow">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                        class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path
                                            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z">
                                        </path>
                                    </svg>
                                </i>
                            </a>
                        </a></li> -->

                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>