import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contact = {
    name: '',
    email: '',
    phone: '',
    address: '',
    message: ''
  };

  onSubmit() {
    // const mailtoLink = `mailto:emywael194@gmail.com?subject=${encodeURIComponent('Contact Us from mohamed888rady@gmail.com')}` +
    // `&body=${encodeURIComponent('Name: ' + this.contact.name + '\n' +
    //                              'Email: ' + this.contact.email + '\n' +
    //                              'Phone: ' + this.contact.phone + '\n' +
    //                              'Address: ' + this.contact.address + '\n' +
    //                              'Message: ' + this.contact.message)}`;
    // window.location.href = mailtoLink;
  }
  constructor() { }

  ngOnInit() {
  }

}
