<div class="banner-section chatbot-main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="content">
                        <div class="banner-content-slides owl-carousel owl-theme">
                            <div class="inner-content">
                                <h1>Jump with us to build your brand</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Try It Free Now<span></span></a>
                            </div>
                            <div class="inner-content">
                                <h1>Best place to talk to strangers</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i>Try It Free Now<span></span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="banner-img bg-3">
                    <app-chatting></app-chatting>
                    <img src="assets/img/banner-img3.jpg" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="shape19"><img src="assets/img/shape/shape18.png" alt="image"></div>
    <div class="shape22"><img src="assets/img/shape/shape21.png" alt="image"></div>
    <div class="shape23"><img src="assets/img/shape/shape22.svg" alt="image"></div>
    <div class="shape24"><img src="assets/img/shape/shape23.png" alt="image"></div>
    <div class="shape25"><img src="assets/img/shape/shape24.png" alt="image"></div>
    <div class="shape26"><img src="assets/img/shape/shape25.png" alt="image"></div>
    <div class="shape20"><img src="assets/img/shape/shape19.png" alt="image"></div>
</div>

<section class="partner-area pt-100 pb-70 bg-f8fbfa">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="partner-title">
                    <h3>Featured by:</h3>
                </div>
            </div>
            <div class="col-lg-9 col-md-12">
                <div class="partner-slides owl-carousel owl-theme">
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img1.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img2.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img3.png" alt="image">
                        </a>
                    </div>
                    <div class="single-partner-item">
                        <a href="#">
                            <img src="assets/img/partner-image/img4.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Amazing Features</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                    <h3>IT Consultancy</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-conversation'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                    <h3>IT Solutions</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bxs-badge-check'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                    <h3>Simple Dashboard</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bxs-dashboard'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                    <h3>Deadline Reminders</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bxs-bell-ring'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                    <h3>Information Retrieval</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bxs-info-circle'></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="features-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="icon">
                        <i class='bx bx-cog'></i>
                    </div>
                    <h3>Flexible Functionality</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="back-icon">
                        <i class='bx bx-cog'></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-right-color ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content">
                <div class="content left-content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>Path is here for faster way of connections with your customers</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/img1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-f4f6fc ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image">
                    <img src="assets/img/services-image/img2.png" alt="image">
                </div>
            </div>
            <div class="services-content">
                <div class="content">
                    <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div>
                    <h2>The best Innovative Chatbot and automations are here to expand</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <a routerLink="/contact" class="default-btn"><i class="bx bxs-spreadsheet"></i> Learn More<span></span></a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="our-loving-clients ptb-100 bg-color-f8fbfa">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>

<section class="testimonials-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="testimonials-slides owl-carousel owl-theme">
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img6.jpg" alt="image">
                    <h3>Michel John</h3>
                    <span>CEO at Envato</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img8.jpg" alt="image">
                    <h3>James Andy</h3>
                    <span>CEO at 3S</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
            <div class="single-testimonials-item">
                <div class="client-info">
                    <img src="assets/img/author-image/img7.jpg" alt="image">
                    <h3>Sarah Taylor</h3>
                    <span>CEO at EnvyTheme</span>
                </div>
                <div class="testimonials-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="pricing-area pt-100 pb-70 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Choose The Pricing Plan</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Free</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>0<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 3 chat operators <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> 100 ChatBot Triggers</li>

                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Starter</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>49<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 4 chat operators <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> 150 ChatBot Triggers</li>

                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="single-pricing-table">
                    <div class="pricing-header">
                        <h3>Professional</h3>
                    </div>
                    <div class="price">
                        <sup>$</sup>79<sub>/m</sub>
                    </div>
                    <ul class="pricing-features">
                        <li><i class="bx bxs-badge-check"></i> Up to 5 chat operators <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> 200 ChatBot Triggers</li>

                        <li><i class="bx bxs-badge-check"></i> 24/7 Live Chat</li>

                        <li><i class="bx bxs-badge-check"></i> Email Integration <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Messenger Integration</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Info</li>

                        <li><i class="bx bxs-badge-check"></i> Mobile + Desktop Apps</li>

                        <li><i class="bx bxs-badge-check"></i> Quick Responses <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Drag & Drop Widgets</li>

                        <li><i class="bx bxs-badge-check"></i> Visitor Notes <span class="tooltips bx bxs-info-circle"  title="Tight pants next level keffiyeh you probably haven't heard of them."></span></li>

                        <li><i class="bx bxs-badge-check"></i> Google Analytics</li>
                    </ul>
                    <div class="btn-box">
                        <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Try It Free Now <span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Meet Our experts always ready to help you</h2>
        </div>
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="image">
                        <img src="assets/img/team-image/img1.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="image">
                        <img src="assets/img/team-image/img2.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Sarah Taylor</h3>
                        <span>UX/UI Designer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="image">
                        <img src="assets/img/team-image/img3.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Lee Munroe</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-team-box wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                    <div class="image">
                        <img src="assets/img/team-image/img4.jpg" alt="image">
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        </ul>
                    </div>
                    <div class="content">
                        <h3>Calvin Klein</h3>
                        <span>Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Supporting your customers on the go with our mobile app</h2>
                    <div class="btn-box">
                        <a href="#" class="apple-store-btn"><img src="assets/img/applestore.png" alt="image">Download on the<span>Apple Store</span></a>
                        <a href="#" class="play-store-btn"><img src="assets/img/playstore.png" alt="image">Get it on<span>Google Play</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Latest News</h2>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img1.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 14, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">50 world-changing work, We lost in the 2010s</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img1.jpg" alt="image">
                                <h6>Sarah Taylor</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img2.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 16, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">Don't buy a tech gift until you read these rules</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img2.jpg" alt="image">
                                <h6>Michel John</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div class="single-blog-post wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                    <div class="post-image">
                        <a routerLink="/blog-details"><img src="assets/img/blog-image/img3.jpg" alt="image"></a>
                        <div class="date"><i class='bx bx-calendar'></i> Oct 18, 2023</div>
                    </div>
                    <div class="post-content">
                        <h3><a routerLink="/blog-details">The golden rule of buying a phone as a gift</a></h3>
                        <div class="post-info">
                            <div class="post-by">
                                <img src="assets/img/author-image/img3.jpg" alt="image">
                                <h6>Lucy Eva</h6>
                            </div>
                            <div class="details-btn">
                                <a routerLink="/blog-details"><i class="bx bx-right-arrow-alt"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="blog-notes wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                    <p>Insights to help you do what you do better, faster and more profitably. <a routerLink="/blog-1">Read Full Blog</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="subscribe-area bg-f4f5fe">
    <div class="container">
        <div class="subscribe-content">
            <h2>We always try to be the best support to you as possible</h2>
            <form class="newsletter-form">
                <div class="row align-items-center">
                    <div class="col-lg-8 col-md-8">
                        <input type="email" class="input-newsletter" placeholder="hello&#64;jexsa.com" name="EMAIL" required>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <button type="submit"><i class="bx bxs-hot"></i> Subscribe Now</button>
                    </div>
                </div>
            </form>
            <div class="shape14"><img src="assets/img/shape/shape13.png" alt="image"></div>
            <div class="shape15"><img src="assets/img/shape/shape14.png" alt="image"></div>
            <div class="shape16"><img src="assets/img/shape/shape15.png" alt="image"></div>
            <div class="shape17"><img src="assets/img/shape/shape16.png" alt="image"></div>
            <div class="shape18"><img src="assets/img/shape/shape17.png" alt="image"></div>
        </div>
    </div>
</section>

<section class="free-trial-area ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="free-trial-content">
            <h2>We always try to be the best support to you as possible</h2>
            <p>Qualify your leads & recognize the value of word your customer will love you</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-hot"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>