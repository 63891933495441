<div class="overflow">
    <div class="position-relative" style="height: 38rem;">
        <div class="MainHeaderWrapper_header_bg__CXa1e MainHeaderWrapper_animate__CLDjY"
            [style.backgroundImage]="'url(assets/img/partner-image/web.png )'"></div>
        <div class="h-100">
            <div class="container h-100 d-flex justify-content-start align-items-center">
                <div class="undefined'col-xl-6 col-12 wow animate__animated animate__fadeInLeft' animated animated"
                    data-wow-duration="2s" style="visibility: visible; animation-duration: 2s;">
                    <p class="mb-xl-4 mb-2  bold-700 font-xl title_ba_blue"></p>
                    <p class="w-70 mb-xl-4 mb-2 title_white bold-700">أضف شحنتك الآن بسهولة ونضمن وصولها بأمان وسرعة</p>
                    <p class="my-xl-5 my-4 MainHeaderWrapper_p_white__exrle"></p>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="features-card-section pt-100 pb-70 bg-f8fbfa" id="خدماتنا">
    <div class="section-title">
        <h2 class="fw-medium">خدماتنا</h2>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <div class="single-features-card tx-center" style="background-color: #09285f;">
                    <!-- <i class='bx bx-conversation'> -->
                    <img src="https://www.alfarescargo.com/storage/2054/truck.svg" alt="" class="img-fluid mb-4">
                    <!-- </i> -->
                    <!-- <h3><a class="text-white">تتبع الشحنات</a></h3> -->
                    <h3><a class="text-white">سرعة تحصيل الأموال </a></h3>
                    <p class="text-white">
                        نضمن لك سرعة وسهولة في تحصيل الأموال مع خدماتنا المالية المتميزة التي تضمن راحة بالك وكفاءة
                        عملياتك.
                    <p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <div class="single-features-card tx-center">
                    <!-- <i class='bx bx-laptop'></i> -->
                    <img src="https://www.aj-ex.com/images/speed_icon.svg" alt="" class="img-fluid mb-4"
                        style="height: 45px; width: 60px;">
                    <!-- <h3><a>شحن سريع</a></h3> -->
                    <h3><a>تقارير الشحنات بصورة منظمة</a></h3>
                    <p>نوفر تقارير شحنات مفصلة وبصورة منتظمة لضمان متابعة دقيقة </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <div class="single-features-card tx-center" style="background-color: #09285f;">
                    <!-- <i class='bx bxs-badge-check'></i> -->
                    <img src="https://www.alfarescargo.com/storage/2389/shield.svg" alt="" class="img-fluid mb-4"
                        style="height: 45px; width: 60px;">
                    <!-- <h3><a class="text-white">دعم العملاء</a></h3> -->
                    <h3><a class="text-white">خدمات التخزين والتوزيع</a></h3>
                    <p class="text-white ">
                        نقدم خدمات تخزين وتوزيع مبتكرة تلبي احتياجاتك بكل كفاءة. تشمل خدماتنا تخزين البضائع في بيئات
                        آمنة .
                    </p>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-md-6 wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                <div class="single-features-card tx-center">
                    <!-- <i class='bx bxs-megaphone'></i> -->
                    <img src="https://www.aj-ex.com/images/access_icon.svg" alt="" class="img-fluid mb-4"
                        style="height: 45px; width: 60px;">
                    <!-- <h3><a>التوصيل إلى أي مكان</a></h3> -->
                    <h3><a>خدمات التأمين علي الشحنات</a></h3>
                    <p>نحن نوفر خدمات التوصيل إلى أي مكان. بغض النظر عن موقعك
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area bg-right-shape ptb-100" id="خدماتنا">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-content it-service-content">
                <div class="content left-content">
                    <!-- <div class="icon">
                        <img src="assets/img/icon1.png" alt="image">
                    </div> -->
                    <h2 class="mb-4">خدمات الشحن المحلي</h2>
                    <p class="fw-medium fs-6">نحن في Laststep نقدم حلول شحن موثوقة وسريعة داخل مصر. سواء كنت تحتاج إلى
                        شحن وثائق، طرود، أو
                        منتجات تجارية، نحن هنا لضمان وصول شحناتك بأمان وفي الوقت المحدد. خدماتنا مصممة لتلبية جميع
                        احتياجاتك اللوجستية بفعالية وكفاءة.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> نضمن وصول شحناتك بأسرع وقت
                                ممكن وبأمان تام.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> تتبع شحناتك واحصل على تحديثات
                                فورية حول حالتها.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> نحن نوفر خدمات التوصيل إلى أي
                                مكان داخل.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> إدارة المخازن والتوزيع لتلبية
                                احتياجاتك التجارية.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> دعم على مدار الساعة لضمان
                                رضاك الكامل.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="services-image wow animate__animated animate__fadeInRight" data-wow-delay=".3s">
                <div class="image" style="text-align: start;">
                    <img src="assets/img/partner-image/how-to.png" alt="image" style="width: 710px; height: 450px">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="services-area ptb-100" id="نبذة_عن_الشركة">
    <div class="section-title">
        <h2 class="fw-medium">ليه تختار <span>LastStep؟</span></h2>
        <!-- <p class="fs-6">نتميز بخدمة عملاء ممتازة، تتبع فوري للشحنات، وأسعار تنافسية.</p> -->
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="services-image wow animate__animated animate__fadeInLeft" data-wow-delay=".3s">
                <div class="image" style="text-align: end;">
                    <img src="assets/img/partner-image/for-web.png" alt="image" style="width: 710px; height: 450px">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <h2>خدمات الشحن السريعة</h2>
                    <p class="fw-medium fs-6">
                        مع LastStep، نلتزم بسرعة وكفاءة في تسليم شحناتك إلى أي مكان داخل مصر. نحن نضمن وصول شحناتك بأسرع
                        وقت ممكن وبأمان تام.
                        فريق دعم العملاء لدينا جاهز لخدمتك على مدار الساعة، لتقديم المساعدة والإجابة على استفساراتك
                        بسرعة وكفاءة. نحن ملتزمون بتقديم تجربة خدمة عملاء استثنائية.
                        حن نهتم بتغليف شحناتك بأفضل الطرق لضمان حمايتها من أي أضرار محتملة أثناء النقل
                    </p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i>
                                دعم العملاء المتاح على مدار الساعة.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> خدمات الشحن السريعة.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i>
                                تتبع الشحنات الفوري.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i> إدارة المخازن والتوزيع لتلبية
                                أسعار تنافسية.
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="mb-4">
                                <i class='bx bxs-badge-check' style="color: #09285f;"></i>
                                خدمات تغليف احترافية.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-area ptb-100" id="الأسئلة_الشائعة">
    <div class="section-title">
        <h2 class="fw-medium">الأسئلة الشائعة</h2>
    </div>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-accordion">
                    <h2 class="fw-medium">تعرف على خدمات <span>Laststep</span></h2>
                    <div class="accordion" id="faqAccordion">
                        <div class="accordion-item">
                            <div class="accordion-header">
                                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    ما هي الخدمات التي تقدمها Laststep؟ <i class="bx bx-plus"></i>
                                </button>
                            </div>
                            <div id="collapseOne" class="accordion-collapse collapse show"
                                data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    نقدم مجموعة واسعة من خدمات الشحن المحلي بما في ذلك الشحن السريع، حلول التخزين،
                                    وخدمات التوصيل إلى أي مكان.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    كيف يمكنني تتبع شحنتي؟ <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    مكنك تتبع شحنتك بسهولة عبر موقعنا الإلكتروني باستخدام رقم التتبع الخاص بك. سنوفر لك
                                    تحديثات فورية عن حالة شحنتك.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    هل تقدمون خدمات شحن دولية؟ <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    حاليًا، نقدم خدمات الشحن المحلي داخل مصر فقط. نعمل على توسيع خدماتنا لتشمل الشحن
                                    الدولي في المستقبل القريب.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    ما هي أوقات عمل خدمة العملاء؟ <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    فريق خدمة العملاء لدينا متاح على مدار الساعة لضمان تقديم الدعم والمساعدة لك في أي
                                    وقت.
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header">
                                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    كيف يمكنني تقديم شكوى أو اقتراح؟ <i class="bx bx-plus"></i>
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#faqAccordion">
                                <div class="accordion-body">
                                    نحن هنا للاستماع لك. يمكنك تقديم شكوى أو اقتراح عبر نموذج الاتصال الموجود على موقعنا
                                    الإلكتروني أو بالاتصال بخدمة العملاء.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="faq-image wow animate__animated animate__fadeInUp" data-wow-delay=".2s"
                    style="text-align: start;">
                    <img src="assets/img/partner-image/Untitled-1.png" alt="image" style="width: 710px; height: 450px">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100 pb-70 bg-f0f2f5">
    <div class="container">
        <div class="section-title">
            <h2 class="text-center fs-2 mb-3" style="font-weight: 600; font-size: 30px;">عملائنا
            </h2>
            <!-- <p class="text-center lh-lg fw-normal" style="font-size: 14px;">
                Discover the groundbreaking projects we've completed for our clients.
                <br>
                See how Orial transforms ideas into success stories.
            </p> -->
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/logos/img1.png" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/logos/img2.png" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/logos/img7.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/logos/img5.png" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/logos/img6.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/logos/img10.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/logos/img11.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/logos/img12.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img13.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img14.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img15.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img16.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img17.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
            <div class="single-clients-logo wow animate__animated animate__fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/logos/img18.jpeg" alt="image" class="rounded-1" />
                </a>
            </div>
        </div>
    </div>
</section>

<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="fw-medium">ما يقوله عملاؤنا عن <span>Laststep</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/logos/pic.png" alt="image">
                <div class="feedback-desc">
                    <p class="text-dark">
                        شركة Laststep قدمت لنا خدمة شحن ممتازة وسريعة. البضائع وصلت في الموعد المحدد وفي حالة ممتازة.
                        التعامل مع فريق العمل كان احترافيًا وساعدنا في كل خطوة من العملية. بالتأكيد سنستخدم خدماتهم مرة
                        أخرى!
                    </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3 class="mt-3">محمد علي</h3>
                        <!-- <span>CEO at Envato</span> -->
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/logos/pic2.png" alt="image">
                <div class="feedback-desc">
                    <p class="text-dark">
                        كانت تجربتي مع Laststep رائعة! فريق الدعم كان متعاونًا جدًا وساعدني في تتبع الشحنة حتى وصلت
                        بأمان. الأسعار معقولة جدًا مقارنة بالخدمات الأخرى المتاحة في السوق. أوصي بشدة بهذه الشركة لأي
                        شخص يبحث عن خدمات شحن موثوقة.
                    </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3 class="mt-3">حسن مجدي</h3>
                        <!-- <span>CEO at Envato</span> -->
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/logos/pic3.png" alt="image">
                <div class="feedback-desc">
                    <p class="text-dark">
                        Laststep شركة رائعة لشحن البضائع. لقد استخدمت خدماتهم عدة مرات ولم أواجه أي مشاكل. البضائع
                        دائمًا تصل في الموعد المحدد وفي حالة ممتازة. بالإضافة إلى ذلك، الفريق متعاون جدًا ويقدم خدمة
                        عملاء ممتازة. أنا راضٍ جدًا عن تجربتي معهم.
                    </p>
                    <!-- <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div> -->
                    <div class="client-info">
                        <h3 class="mt-3">علي أحمد</h3>
                        <!-- <span>CEO at Envato</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>