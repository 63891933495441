<div class="{{navbarClass}}">
        <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                        <!-- <a class="symbol" routerLink="/">
                                <img src="assets/img/logo.jpg" alt="logo">
                        </a> -->
                        <div class="d-flex align-items-center">
                                <a class="symbol" routerLinkActive="active menu-here" routerLink="/">
                                        <img alt="Logo" src="assets/img/logo.jpg" class="app-sidebar-logo-default" />
                                </a>
                                <!-- <h5 class="my-0 mx-2">{{ companyName }}</h5> -->
                        </div>

                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul class="navbar-nav">
                                        <!--  <li class="nav-item"><a routerLink="/" 
                                                        routerLinkActive="active" class="nav-link fs-6">الرئيسية
                                                       <i class='bx bx-chevron-down'></i>
                                                </a>
                                                 <ul class="dropdown-menu">
                                                        <li class="nav-item"><a href="#" class="nav-link">With Animation
                                                                        Home <i class='bx bx-chevron-left'></i></a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/" class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        One (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-2"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Two (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-3"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Three (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-4"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Four (SaaS Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-5"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Five (Chatbot)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-6"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Six (Chatbot)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/home-7"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Seven (Chatbot)</a></li>
                                                                </ul>
                                                        </li>

                                                        <li class="nav-item"><a href="#" class="nav-link">Without
                                                                        Animation Home <i
                                                                                class='bx bx-chevron-left'></i></a>
                                                                <ul class="dropdown-menu">
                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-1"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        One
                                                                                        (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-2"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Two
                                                                                        (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-3"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Three (IT Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-4"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Four (SaaS Startup)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-5"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Five (Chatbot)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-6"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Six
                                                                                        (Chatbot)</a></li>

                                                                        <li class="nav-item" data-toggle="collapse"
                                                                                data-target=".navbar-collapse.show"><a
                                                                                        routerLink="/without-animation-home-7"
                                                                                        class="nav-link"
                                                                                        routerLinkActive="active"
                                                                                        [routerLinkActiveOptions]="{exact: true}">Home
                                                                                        Seven (Chatbot)</a></li>
                                                                </ul>
                                                        </li>
                                                </ul>
                                        </li>
                                        
                                        <li class="nav-item"><a class="nav-link fs-6">خدماتنا </a>
                                                 <ul class="dropdown-menu">
                                        <li class="nav-item"><a href="#" class="nav-link">Service <i
                                                                class='bx bx-chevron-left'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/services-1"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Services
                                                                        One</a></li>

                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/services-2"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Services
                                                                        Two</a></li>

                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/services-details"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Service
                                                                        Details</a></li>
                                                </ul>
                                        </li>

                                        <li class="nav-item"><a href="#" class="nav-link">Features <i
                                                                class='bx bx-chevron-left'></i></a>
                                                <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/features-1"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Features
                                                                        Style One</a></li>

                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/features-2"
                                                                        class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Features
                                                                        Style Two</a></li>
                                                </ul>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a
                                                        routerLink="/team" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Team</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a
                                                        routerLink="/log-in" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Log
                                                        In</a></li>

                                        <li class="nav-item" data-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a
                                                        routerLink="/sign-up" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">Sign
                                                        Up</a></li>

                                        <li class="nav-item" data-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a routerLink="/faq"
                                                        class="nav-link" routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse"
                                                data-target=".navbar-collapse.show"><a
                                                        routerLink="/error" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">404
                                                        Error Page</a></li>
                                </ul>
                                        </li>  -->

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink class="nav-link fs-6" fragment="الرئيسية"
                                                        [ngClass]="{'active': currentFragment === 'الرئيسية'}">الرئيسية</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink class="nav-link fs-6" fragment="خدماتنا"
                                                        [ngClass]="{'active': currentFragment === 'خدماتنا'}">خدماتنا</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink class="nav-link  fs-6" fragment="نبذة_عن_الشركة"
                                                        [ngClass]="{'active': currentFragment === 'نبذة_عن_الشركة'}">نبذة
                                                        عن الشركة</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a routerLink class="nav-link fs-6" fragment="الأسئلة_الشائعة"
                                                        [ngClass]="{'active': currentFragment === 'الأسئلة_الشائعة'}">الأسئلة
                                                        الشائعة</a>
                                        </li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                                <a class="nav-link fs-6" routerLink="contact" class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{exact: true}">اتصل
                                                        بنا</a>
                                        </li>

                                        <!-- <li class="nav-item"> 
                                                 <a class="nav-link fs-6">الأسئلة الشائعة
                                                        <i class='bx bx-chevron-down'></i>
                                                </a>
                                                 <ul class="dropdown-menu">
                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blog-1" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Grid</a></li>

                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blog-2" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Right Sidebar</a></li>

                                                        <li class="nav-item" data-toggle="collapse"
                                                                data-target=".navbar-collapse.show"><a
                                                                        routerLink="/blog-details" class="nav-link"
                                                                        routerLinkActive="active"
                                                                        [routerLinkActiveOptions]="{exact: true}">Blog
                                                                        Details</a></li>
                                                </ul> 
                                        </li> -->
                                </ul>
                        </div>
                </nav>
        </div>
</div>