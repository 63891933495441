<footer class="footer-area pt-4" style="background-color: #F0F2F5;">
    <!-- <div class="divider"></div> -->
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a class="symbol" routerLinkActive="active menu-here" routerLink="/">
                        <img alt="Logo" src="assets/img/logo.jpg" class="app-sidebar-logo-default" />
                    </a>
                    <p class="fs-6 text-dark fw-medium">شركة Laststep هي واحدة من الشركات الرائدة التي تقدم خدمات
                        الشحن بتميز واحترافية. تأسست الشركة بهدف تلبية احتياجات السوق في مجال الشحن
                        والخدمات اللوجستية من خلال توفير حلول شحن مبتكرة وفعالة. تسعى Laststep دائمًا لتطوير خدماتها
                        لتواكب أحدث التقنيات وتلبية متطلبات العملاء بكل كفاءة.</p>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="text-dark">الشركة</h3>
                    <ul class="services-list">
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">خدماتنا</a></li>
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">تتبع شحنتك</a></li>
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">نبذه عن الشركة</a></li>
                        <li><a class="fs-6 text-dark fw-medium" routerLink="contact" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">اتصل بنا</a></li>

                        <!-- <li><a class="fs-6 text-dark" routerLink="/">Our Pricing</a></li>
                        <li><a class="fs-6 text-dark" routerLink="/">Latest News</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="text-dark">القوانين والأحكام</h3>
                    <ul class="support-list">
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">سياسة الخصوصية</a></li>
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">شروط الاستخدام</a></li>
                        <li><a class="fs-6 text-dark fw-medium" routerLink="/">الأسئلة الشائعة</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3 class="text-dark">تواصل معانا</h3>
                    <ul class="footer-contact-info">
                        <li class="fs-6 text-dark fw-medium">
                            <!-- العنوان: -->
                            <a class="text-dark fw-medium" href="https://maps.app.goo.gl/CW9UgA7yJSAMBane8"
                                target="_blank">
                                فرع القناطر الخيرية:
                                سور نادي القناطر الخيرية الشارع المقابل لمكتبة غريب.

                            </a>
                            <a class="text-dark fw-medium" href="https://maps.app.goo.gl/u4K6dZhLbiht3ZXXA"
                                target="_blank">
                                فرع قليوب: دا الرئيسي حالياً
                                قليوب أمام المركز الطبي أعلي صيدلية العزبي عالطريق مباشرة

                            </a>
                        </li>
                        <li class="fs-6 text-dark fw-medium">الايميل: <a class="text-dark"
                                href="mailto:hello&#64;jexsa.com">sales&#64;laststepeg.co</a>
                        </li>
                        <li class="fs-6 text-dark fw-medium">رقم الهاتف:
                            <a class="text-dark fw-medium" href="tel:+201099054099"> 01099054099 </a> -
                            <a class="text-dark fw-medium" href="tel:+201099039037"> 01099039037 </a>
                        </li>
                    </ul>
                    <ul class="social">
                        <li class="amx-social-item"><a href="https://www.facebook.com/LastStep700?mibextid=ZbWKwL"
                                class="amx-fb" rel="nofollow" target="blank"><i><svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="23.855" viewBox="0 0 24 23.855">
                                        <defs></defs>
                                        <path class="a"
                                            d="M24,12A12,12,0,1,0,10.125,23.854V15.469H7.078V12h3.047V9.357c0-3.007,1.792-4.669,4.533-4.669a18.418,18.418,0,0,1,2.686.235V7.876H15.83A1.735,1.735,0,0,0,13.874,9.75V12H17.2l-.532,3.47h-2.8v8.385A12,12,0,0,0,24,12Z">
                                        </path>
                                    </svg></i></a></li>
                        <!-- <li class="amx-social-item"><a href="https://www.instagram.com/aramex/" class="amx-ig"
                                rel="nofollow"><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24">
                                        <defs></defs>
                                        <path class="a"
                                            d="M12,0C8.74,0,8.333.015,7.053.072A8.849,8.849,0,0,0,4.14.63,5.876,5.876,0,0,0,2.014,2.014,5.855,5.855,0,0,0,.63,4.14,8.823,8.823,0,0,0,.072,7.053C.012,8.333,0,8.74,0,12s.015,3.667.072,4.947A8.854,8.854,0,0,0,.63,19.86a5.885,5.885,0,0,0,1.384,2.126A5.868,5.868,0,0,0,4.14,23.37a8.86,8.86,0,0,0,2.913.558C8.333,23.988,8.74,24,12,24s3.667-.015,4.947-.072a8.88,8.88,0,0,0,2.913-.558,6.133,6.133,0,0,0,3.51-3.51,8.854,8.854,0,0,0,.558-2.913C23.988,15.667,24,15.26,24,12s-.015-3.667-.072-4.947A8.875,8.875,0,0,0,23.37,4.14a5.89,5.89,0,0,0-1.384-2.126A5.847,5.847,0,0,0,19.86.63,8.828,8.828,0,0,0,16.947.072C15.667.012,15.26,0,12,0Zm0,2.16c3.2,0,3.585.016,4.85.071a6.611,6.611,0,0,1,2.227.415,3.949,3.949,0,0,1,2.278,2.277,6.625,6.625,0,0,1,.413,2.227c.057,1.266.07,1.646.07,4.85s-.015,3.585-.074,4.85a6.753,6.753,0,0,1-.421,2.227,3.81,3.81,0,0,1-.9,1.382,3.744,3.744,0,0,1-1.38.9,6.674,6.674,0,0,1-2.235.413c-1.274.057-1.649.07-4.859.07s-3.586-.015-4.859-.074a6.8,6.8,0,0,1-2.236-.421,3.716,3.716,0,0,1-1.379-.9,3.644,3.644,0,0,1-.9-1.38,6.81,6.81,0,0,1-.42-2.235c-.045-1.26-.061-1.649-.061-4.844s.016-3.586.061-4.861A6.8,6.8,0,0,1,2.6,4.89a3.557,3.557,0,0,1,.9-1.381,3.549,3.549,0,0,1,1.379-.9A6.642,6.642,0,0,1,7.1,2.19c1.275-.045,1.65-.06,4.859-.06L12,2.16Zm0,3.678A6.162,6.162,0,1,0,18.162,12,6.162,6.162,0,0,0,12,5.838ZM12,16a4,4,0,1,1,4-4A4,4,0,0,1,12,16ZM19.846,5.595a1.44,1.44,0,1,1-1.44-1.439A1.441,1.441,0,0,1,19.846,5.595Z">
                                        </path>
                                    </svg></i></a></li>

                        <li class="amx-social-item">
                            <a href="https://twitter.com/aramex" class="amx-twtr" rel="nofollow">
                                <i>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
                                        class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path
                                            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z">
                                        </path>
                                    </svg>
                                </i>
                            </a>
                        </li>
                        <li class="amx-social-item"><a href="https://www.linkedin.com/company/aramex" class="amx-ln"
                                rel="nofollow"><i><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                        viewBox="0 0 24 24">
                                        <defs></defs>
                                        <path class="a"
                                            d="M20.447,20.452H16.893V14.883c0-1.328-.027-3.037-1.852-3.037-1.853,0-2.136,1.445-2.136,2.939v5.667H9.351V9h3.414v1.561h.046a3.745,3.745,0,0,1,3.37-1.85c3.6,0,4.267,2.37,4.267,5.455v6.286ZM5.337,7.433A2.064,2.064,0,1,1,7.4,5.368,2.062,2.062,0,0,1,5.337,7.433ZM7.119,20.452H3.555V9H7.119ZM22.225,0H1.771A1.75,1.75,0,0,0,0,1.729V22.271A1.749,1.749,0,0,0,1.771,24H22.222A1.756,1.756,0,0,0,24,22.271V1.729A1.756,1.756,0,0,0,22.222,0Z">
                                        </path>
                                    </svg></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
        <div class="copyright-area mt-0 pt-2">
            <p class="fs-6 text-dark fw-medium">
                جميع حقوق النشر محفوظة © {{ currentYear }} | {{ companyName }} لخدمات الشحن والنقل
            </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>