import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {

    location: any;
    navbarClass: any;
    currentFragment: string = '';
    
    constructor(
        private router: Router,
        location: Location
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          ).subscribe(() => {
            const url = this.router.url;
            const fragmentIndex = url.indexOf('#');
            this.currentFragment = fragmentIndex !== -1 ? url.slice(fragmentIndex + 1) : '';
          });
          
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/' || this.location == '/home-2' || this.location == '/home-3' || this.location == '/home-5' || this.location == '/home-6' || this.location == '/home-7' || this.location == '/without-animation-home-1' || this.location == '/without-animation-home-2' || this.location == '/without-animation-home-3' || this.location == '/without-animation-home-5' || this.location == '/without-animation-home-6' || this.location == '/without-animation-home-7'){
                    // this.navbarClass = 'navbar-area';
                    this.navbarClass = 'navbar-area navbar-style-two';
                } else if (this.location == '/home-4' || this.location == '/without-animation-home-4'){
                    // this.navbarClass = 'navbar-area bg-white';
                    this.navbarClass = 'navbar-area navbar-style-two';
                } else {
                    this.navbarClass = 'navbar-area navbar-style-two';
                }
            }
        });
    }
    ngOnInit(): void {
        throw new Error('Method not implemented.');
    }
    
}